.codeWrapper {
    position: relative;
    width: 100%;
    padding: 54px 20px 0 10px;
    max-width: 1200px;
    margin-bottom: 80px;
    background: #21282F;
    border: 1px solid #49525A;
    border-radius: 16px;
    overflow: hidden;
}

.code {
    width: 100%;
    height: 476px;
    overflow-y: auto;
}

.code span {
    font-size: 14px;
}

.code::-webkit-scrollbar {
    width: auto;
}

.code::-webkit-scrollbar-track {
    width: 2px;
    background-color: #49525A;
    border: 6px solid transparent;
    background-clip: content-box;
}

.code::-webkit-scrollbar-thumb {
    background-color: #B0B7BF;
    height: 125px;
    width: 6px;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.code::-webkit-scrollbar-corner {
    width: 0;
    background: transparent;
}

.code p {
    font-family: 'Space Mono', monospace;
    font-style: normal;
    font-size: 12px;
    line-height: 140%;
    color: #ECEDF1;
}

.code pre {
    overflow: unset;
}

.code pre::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.fileListButton, .oneFileButton {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
    left: 20px;
    top: 20px;
    width: auto;
    height: 34px;
    padding: 10px 20px;
    border-radius: 17px;
    background: #49525A;
    border: 0;
}

.oneFileButton {

}

.fileListButton p, .oneFileButton p, .fileRow, .currentFileRow p {
    font-family: 'Space Mono', monospace;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    color: #ECEDF1;
}

.fileList {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    left: 20px;
    top: 20px;
    width: auto;
    border-radius: 17px;
    background: #49525A;
}

.currentFileRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
}

.fileRow, .currentFileRow {
    padding: 10px 20px;
}

.fileRow:hover, .currentFileRow:hover {
    background: rgba(236, 237, 241, 0.20);
    cursor: pointer;
}


@media (max-width: 500px) {
    .codeWrapper {
        padding: 54px 20px 0 20px;
    }

    .code {
        -ms-overflow-style: none;
    }

    .code::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }
}

@media (max-width: 500px) {
    .fileListButton, .oneFileButton, .fileList {
        top: 20px;
        left: 20px;
    }
}
