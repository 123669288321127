
.addressBlock {
    display: flex;
    flex-direction: column;
    width: 655px;
    height: auto;
    padding: 20px;
    background: #21282F;
    border: 1px solid #49525A;
    border-radius: 16px;
}

.addressBlockTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.name {
    font-weight: 600;
    font-size: 30px;
    margin-left: 10px;
}

.addressBlockMiddle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
}

.copyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    line-height: 120%;
    color: #ECEDF1;
    height: 26px;
}

.copyButton:hover {
    filter: opacity(0.4);
}

.copyButton img {
    margin-bottom: 1px;
    margin-left: 12px;
}

.unverifiedText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.unverifiedText p, .unverifiedText a {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
}

.unverifiedText a {
    color: #EBDA3D;
    text-decoration-line: underline;
}

.address {
    display: block;
}

.addressText {
    font-size: 24px;
}

.verifiedIcon {

}

.verifiedLabel, .unverifiedLabel {
    display: flex;
    padding: 5px 20px 5px 5px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: rgba(25, 203, 118, 0.10);
}

.verifiedLabel p, .unverifiedLabel p {
    color: #ECEDF1;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
}

.verifiedLabel {
    border: 1px solid #19CB76;
}

.unverifiedLabel {
    border: 1px solid #EBDA3D;
    background: rgba(235, 218, 61, 0.10);
}

.addressBlockBottom, .deployWithBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.addressBlockBottom {
    justify-content: space-between;
}

.deployWithLink {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.deployWithLink p {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
}

.downloadMetadata {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: #49525A;
}

.downloadMetadata:hover {
    cursor: pointer;
    filter: brightness(120%);
}

.downloadMetadata p {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

@media (max-width: 1340px) {
    .addressBlock {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .addressBlockMiddle {
        flex-direction: column-reverse;
        gap: 14px;
    }
}

@media (max-width: 670px) {
    .addressBlockBottom {
        align-items: flex-start;
        flex-direction: column;
        gap: 20px
    }
}

@media (max-width: 490px) {
    .addressBlockTop, .addressBlockMiddle {
        margin-bottom: 20px;
    }

    .deployWithBlock {
        align-items: flex-start;
        flex-direction: column;
        gap: 20px
    }

    .addressText {
        font-size: 18px;
    }

    .copyButton img {
        width: 12px;
        margin-left: 5px;
    }

    .name {
        font-size: 20px;
    }

    .addressBlock {
        padding: 20px;
    }

    .verifiedLabel, .unverifiedLabel {
        padding: 5px 12px 5px 5px;
        gap: 6px;
    }

    .verifiedLabel p, .unverifiedLabel p {
        font-size: 14px;
    }

    .verifiedLabel img, .unverifiedLabel img {
        width: 20px;
        height: 20px;
    }
}