.inputWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 738px;
    height: 56px;
}

.searchIcon {
    position: absolute;
    left: 18px;
    top: 18px;
    z-index: 10;
}

.inputSearch, .inputSearchError {
    z-index: 8;
    position: absolute;
    width: 93%;
    height: 56px;
    background: #141D25;
    border: 1px solid #4170E7;
    border-radius: 7px;
    padding-left: 45px;
    outline-style: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #ECEDF1;
}

.inputSearch::placeholder, .inputSearchError::placeholder {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
}

.inputSearch::placeholder {
    color: #49525A;
}

.inputSearchError::placeholder {
    color: #FF0C0C;
}

.searchButton {
    z-index: 7;
    position: absolute;
    right: 0;
    height: 56px;
    width: 60px;
    background: #4170E7;
    border-radius: 0px 10px 10px 0;
    border: 0;
}

.searchButton:hover {
    filter: brightness(100%);
}

.searchButtonIcon {
    z-index: 10;
    position: absolute;
    right: 15px;
    top: 18px;
}

.searchButtonIcon:hover {
    cursor: pointer;
}

@media (max-width: 790px) {
    .inputWrapper {
        width: 100%;
    }

    .inputSearch, .inputSearchError {
        width: calc(100% - 50px);
    }
}

@media (max-width: 460px) {
    .inputSearch, .inputSearchError {
        width: calc(100% - 45px);
        padding-left: 12%;
    }

    .searchButton {
        width: 55px;
    }

    .searchButtonIcon {
        right: 12px;
    }
}