.contractContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141D25;
}

.mainBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 180px;
    width: 1200px;
}

.addressAndButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    column-gap: 20px;
    row-gap: 20px;
}

@media (max-width: 1440px) {
    .mainBlock {
        width: 83.3%;
    }
}

@media (max-width: 1340px) {
    .addressAndButtons {
        flex-direction: column;
    }

    .mainBlock {
        padding-top: 146px;
    }
}

@media (max-width: 460px) {
    .mainBlock {
        padding-top: 120px;
        width: 92%;
    }
}