@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.pageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141D25;
}

.mainBlock {
    display: flex;
    flex-direction: column;
    padding-top: 192px;
    width: 840px;
    padding-bottom: 80px;
}

.mainBlock div {
    scroll-margin: 40px;
}

.mainTitle {
    font-size: 32px;
}

.secondTitle {
    font-size: 24px;
    margin-top: 20px;
}

.docsText, .videoText p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.docsText, .videoText {
    margin-bottom: 20px;
}

.videoText {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.videoText img {
    width: 30px;
    margin-right: 6px;
    margin-bottom: 1px;
}

.secondTitle, .mainTitle {
    text-align: left;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: #ECEDF1;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #49525A;
}

.tomlFile {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #ECEDF1;
    margin-bottom: 10px;
    max-width: 130px;
}

.tomlFile p {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.codeExample {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    width: 100%;
    padding: 20px 15px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #1C2934;
}

.codeExample p {
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 1440px) {
    .mainBlock {
        width: 58.3%;
    }
}

@media (max-width: 860px) {
    .mainBlock {
        padding-top: 200px;
        width: 83.3%;
    }
}

@media (max-width: 460px) {
    .mainBlock {
        padding-top: 140px;
        width: 92%;
    }
}
