.baseHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.baseHeader {
    position: absolute;
    top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    padding: 20px;
    background: #21282F;
    border: 1px solid #49525A;
    border-radius: 20px;
}

.patronLogo {

}

@media (max-width: 1440px) {
    .baseHeader {
        width: 83.3%;
    }
}

@media (max-width: 940px) {
    .baseHeader {
        top: 80px;
        border-top: 1px solid #49525A;
    }
}

@media (max-width: 460px) {
    .baseHeader {
        width: 93%;
    }
}