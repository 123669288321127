.info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
}

.infoBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    background: #21282F;
    padding: 20px;
    row-gap: 16px;
    border: 1px solid #49525A;
    box-shadow: 0px 10px 80px -10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.infoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.infoHeader {
    font-size: 14px;
    font-weight: 600;
    margin-right: 30px;
    width: 92px;
}

.hashText, .hashTextVerified {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    cursor: pointer;
}

.hashText {
    text-decoration: underline;
}

.hashText:hover {
    filter: opacity(0.4);
}

.hashTextVerified {
    color: #4170E7;
}

.infoText, .infoTextOwner {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ECEDF1;
}

.infoTextOwner {
    text-decoration: underline;
}

.infoTextOwner:hover {
    cursor: pointer;
    filter: opacity(0.4);
}


.ListHeader {
    position: relative;
    width: 100%;
    height: auto;
    background: #21282F;
    border: 1px solid #49525A;
    box-shadow: 0px 10px 80px -10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.eventListBlock {
    display: flex;
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 56px;
    padding: 10px;
    margin-top: -1px;

    background: #21282F;
    border: 1px solid #49525A;
    box-shadow: 0px 10px 80px -10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    overflow: hidden;
}

.eventsList {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-right: 10px;
    overflow: auto;
}

.eventsList::-webkit-scrollbar {
    width: auto;
}

.eventsList::-webkit-scrollbar-track {
    width: 2px;
    background-color: #49525A;
    border: 6px solid transparent;
    background-clip: content-box;
}

.eventsList::-webkit-scrollbar-thumb {
    background-color: #B0B7BF;
    height: 125px;
    width: 6px;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.listRow {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #29323B;
    border-radius: 10px;
    padding: 20px;
}

.listColumnHeader {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #ECEDF1;
}


.ContractHeader, .TimeHeader, .EventHeader {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #ECEDF1;
    text-transform: uppercase;
}

.ContractHeader {
    position: absolute;
    left: 281px;
}

.TimeHeader {
    position: absolute;
    left: 551px;
}

.EventHeader {
    width: 130px
}

.contract, .contractMobile, .time, .event {
    font-weight: 400;
    font-size: 14px;
    color: #ECEDF1;
}

.contract {
    display: block;
    position: absolute;
    left: 271px;
}

.contractMobile {
    display: none;
}

.time {
    position: absolute;
    left: 541px;
}

.event {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;
    width: 130px
}

.event p {
    font-size: 14px;
    line-height: 120%;
    color: #ECEDF1;
}

.noDataText {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 600;
    font-size: 24px;
}

@media (max-width: 980px) {
    .listRow {
        padding: 20px;
    }

    .contract {
        display: none;
    }

    .contractMobile {
        display: block;
    }

    .contractMobile, .ContractHeader {
        width: 110px;
        position: static;
        margin-left: 10%;
    }

    .TimeHeader, .time {
        width: 114px;
        position: static;
        left: 0;
        margin-left: 10%;
    }

    .eventWrapper, .EventHeader {

    }

    .event {
        position: static;
        font-size: 14px;
    }

    .EventHeader {
        position: static;
    }
}

@media (max-width: 700px) {
    .ContractHeader, .TimeHeader, .EventHeader {
        font-size: 14px;
    }

    .time {
        font-size: 12px;
    }

    .infoBlock {
        padding: 20px;
    }

    .ListHeader {
        padding: 20px;
    }

    .listRow {
        padding: 10px;
    }

    .contractMobile {
        font-size: 12px;
    }

    .event, .EventHeader {
        width: 110px
    }

    .event p {
        font-size: 12px;
    }

    .eventsList::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .eventsList {
        padding-right: 0;
    }

    .noDataText {
        font-size: 16px;
    }
}
