.logBlockWrapper {
    width: 100%;
    max-width: 1200px;
    background: #21282F;
    border: 1px solid #49525A;
    box-shadow: 0px 10px 80px -10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    margin-bottom: 45px;
    overflow: hidden;
    padding: 20px 20px 0 20px;
}

.logBlock {
    width: 100%;
    height: 511px;
    padding-right: 10px;
    overflow: auto;
}

.logBlock::-webkit-scrollbar {
    width: auto;
}

.logBlock::-webkit-scrollbar-track {
    width: 2px;
    background-color: #49525A;
    border: 6px solid transparent;
    background-clip: content-box;
}

.logBlock::-webkit-scrollbar-thumb {
    background-color: #B0B7BF;
    height: 125px;
    width: 6px;
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.logBlock p {
    font-family: 'Space Mono', monospace;
    font-style: normal;
    font-size: 12px;
    line-height: 140%;
    color: #FFFFFF;
}

@media (max-width: 500px) {
    .logBlockWrapper {
        padding: 20px 20px 0 20px;
    }

    .logBlock {
        -ms-overflow-style: none;
    }

    .logBlock::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
}
