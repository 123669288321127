.userContextBlock {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
}

@media (max-width: 1440px) {
    .userContextBlock {
        right: 8.3%;
    }
}

@media (max-width: 790px) {
    .userContextBlock {
        right: 4%;
    }
}