.connectToPatron, .connectedToPatron {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    float: left;

    border-radius: 8px;
    border: 1px solid #49525A;
}

.connectToPatron p, .connectedToPatron {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: #ECEDF1;
}

.connectedToPatron {
    background: #49525A;
}