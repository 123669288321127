@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

/* relative for copy icon */
.wrapper {
    width: 100%;
    position: relative;
}

.codeBlock {
    width: 100%;
    padding: 20px 15px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #1C2934;
    overflow: auto;
}

.codeBlock {

}

.copySquare {
    display: none;
    cursor: pointer;
}

.codeBlock:hover .copySquare {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 17px;
    right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #49525A;
}

.codeBlock p {
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-weight: 400;
}

.codeBlock p {
    overflow: hidden;
    white-space: nowrap;
    display: inline;
}

/* width */
.codeBlock::-webkit-scrollbar {
    height: 8px;
}

/* Track */
.codeBlock::-webkit-scrollbar-track {
    background:#131516;
}

/* Handle */
.codeBlock::-webkit-scrollbar-thumb {
    background: #49525A;
}
