.mainContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141D25;
}

.loginButtonWrapper {
    position: absolute;
    top: 40px;
    right: 156px;
}

.mainDiv {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 30%;
    margin-bottom: 240px;
}

.mainLogo {
    width: 394px;
}

.polkadotNote {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
    margin-bottom: 60px;
}

.polkadotNote p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #49525A;
}

.homePageHeaderBlocks {
    position: absolute;
    top: 40px;
    right: 156px;
}

@media (max-height: 940px) {
    .mainDiv {
        position: absolute;
        top: 34%;
        padding-top: 0;
    }
}

@media (max-height: 900px) and (max-width: 640px){
    .mainDiv {
        position: static;
        padding-top: 220px;
        margin-bottom: 180px;
    }
}

@media (max-height: 740px) and (min-width: 641px){
    .mainDiv {
        position: static;
        padding-top: 220px;
        margin-bottom: 100px;
    }
}

@media (max-width: 1200px) {
    .loginButtonWrapper, .homePageHeaderBlocks {
        right: 3%;
    }
}

@media (max-width: 790px) {
    .mainDiv {
        width: 94%;
    }

    .loginButtonWrapper, .homePageHeaderBlocks {
        right: 3%
    }
}

@media (max-width: 460px) {
    .mainLogo {
        width: 70%;
    }

    .polkadotNote {
        margin-bottom: 40px;
    }
}