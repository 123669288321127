.loginButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 43px;
    background: gray;
    border-radius: 8px;
    border: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ECEDF1;
}