.footerContainer {
    position: fixed;
    width: 100%;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    row-gap: 10px;
}

.footer p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.footerFundedBy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    margin-bottom: 20px;
}

.footerFundedBy p {
    color: #68737E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.footerFundedBy img {
    display: block;
}

.footerDocsAndSocial {
    display: flex;
    flex-direction: row;
}

.footerDocsWrapper {

}

.footerDocs {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding: 0 18px;
    align-items: center;
    width: 156px;
    height: 36px;
    background: #49525A;
    border-radius: 30px;
}

.footerDocs p {
    font-weight: 400;
    font-size: 16px;
    color: #ECEDF1;
    padding-top: 2px;
}

.footerDocs:hover {
    cursor: pointer;
    filter: brightness(120%);
}

.footerContactsBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    height: 36px;
    padding: 0 20px;
    margin: 0 20px;
    border-left: 1px solid #ECEDF1;
    border-right: 1px solid #ECEDF1;
}

.footerContactsBlock a {
    display: flex;
    flex-direction: row;
}

.footerMobileBorder {
    display: none;
}

@media (max-height: 900px) and (max-width: 640px){
    .footerContainer {
        position: static;
        padding-bottom: 40px;
    }
}

@media (max-height: 740px) and (min-width: 641px){
    .footerContainer {
        position: static;
        padding-bottom: 40px;
    }
}

@media (max-width: 640px) {
    .footer {
        width: 94%;
        flex-direction: column;
        row-gap: 16px;
    }

    .footerContactsBlock {
        border: none;
        padding: 0;
        margin: 0;
    }

    .footerMobileBorder {
        display: block;
        width: 100%;
        border-bottom: 1px solid #49525A;
    }

    .footerDocsAndSocial {
        column-gap: 30px;
    }

    .footerFundedBy {
        column-gap: 14px;
        margin-bottom: 16px;
    }

    .footerFundedBy img {
        transform: scale(0.9);
    }

    .footerFundedBy p {
        font-size: 13px;
    }
}