.contractButtons, .contractButtonsRow {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: flex-end;
}

.info, .log, .code, .caller {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ECEDF1;
    height: 40px;
}

.info {
    width: 95px;
}

.log {
    width: 126px;
}

.code {
    width: 102px;
}

.caller {
    width: 103px;
}

@media (max-width: 600px) {
    .contractButtons {
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
    }

    .caller {
        height: 40px;
    }
}

@media (max-width: 460px) {
    .contractButtons {
        row-gap: 10px;
    }

    .contractButtonsRow {
        column-gap: 10px;
    }
}
